import {Abstract_workers_view_controller} from "packs/worker/javascripts/abstract_workers_view_controller";
import {Logger} from "packs/commons/javascripts/logger";
import Vue from 'vue/dist/vue.esm';
import $$ from "jquery";
import {LoadingView} from "packs/loading_view";
import {AlertDialog} from "packs/alert_dialog";

class Faqs extends Abstract_workers_view_controller {

    get class_name(): string {
        return 'faqs';
    }

    initialize() {
    }

    init_index(self) {
        // FAQー一覧Vue
        let faqsVue = new Vue({
            el: '#faqs_vue',
            data: {
                faqCategories: [],
                faqs: []
            },
            computed: {
                refinedFaqCategories() {
                    return this.faqCategories.filter(faqCategory => faqCategory.status == 1)
                        .sort((a,b) => {
                            if(a.sortNo === null) return 1;
                            if(b.sortNo === null) return -1;
                            if (a.sortNo === b.sortNo) return 0;
                            return a.sortNo > b.sortNo  ? 1 : -1;
                        });
                }
            },
            created() {
                this.getFaqCategories();
                this.getFaqs();
            },
            methods: {
                scroll(el) {
                    $(window).scrollTop($(el).position().top);
                },
                autoLink(text) {
                    return text.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1'>$1</a>");
                },
                getFaqsByFaqCategoryId(faqCategoryId) {
                    let faqs = this.faqs.filter(faq => faq.faqCategoryId == faqCategoryId && faq.status == 1);
                    return faqs.sort((a,b) => a.sortNo > b.sortNo  ? 1 : -1);
                },
                // 一覧取得API
                getFaqs() {
                    LoadingView.show();
                    $.ajax({
                        method: 'get',
                        url: "/faqs.json",
                    }).done((data) => {
                        LoadingView.hide();
                        this.faqs = data.map(faq => {
                            return {
                                id: faq.id,
                                faqCategoryId: faq.faq_category_id,
                                title: faq.title,
                                body: faq.body,
                                status: faq.status,
                                sortNo: faq.sort_no
                            }
                        });
                    }).fail(() => {
                        LoadingView.hide();
                    });
                },
                // 一覧取得API
                getFaqCategories() {
                    LoadingView.show();
                    $.ajax({
                        method: 'get',
                        url: "/faq_categories.json",
                    }).done((data) => {
                        LoadingView.hide();
                        this.faqCategories = data.map(faq_categories => {
                            return {
                                id: faq_categories.id,
                                name: faq_categories.name,
                                status: faq_categories.status,
                                sortNo: faq_categories.sort_no
                            }
                        });
                    }).fail(() => {
                        LoadingView.hide();
                    });
                }
            }
        });
    }
}

new Faqs();