import Vue from 'vue/dist/vue.esm';
import $ from "jquery";

export class AlertDialog {
    private static dialog;
    private static elment_id: string = '#alert-modal';

    static showYesNo(message: string, yesCallBack = () => {
    }, noCallBack = () => {
    }) {
        this.dialog.show(message, yesCallBack, noCallBack);
    }

    static showOk(message: string, okValue: string = 'OK', okCallBack = () => {
    }) {
        this.dialog.showOk(message, okValue, okCallBack);
    }

    static loadAlertVue() {
        this.dialog = new Vue({
            el: this.elment_id,
            data: {
                message: '',
                okValue: 'OK',
                isYesNo: true,
                yesCallback: () => {
                },
                noCallback: () => {
                },
                okCallback: () => {
                }
            },
            methods: {
                yes: () => {
                    this.dialog.yesCallback();
                    $(this.elment_id).modal('hide');
                },
                no: () => {
                    this.dialog.noCallback();
                    $(this.elment_id).modal('hide');
                },
                ok: () => {
                    this.dialog.okCallback();
                    $(this.elment_id).modal('hide');
                },
                show: (message, yesCallback = () => {
                }, noCallback = () => {
                }) => {
                    this.dialog.isYesNo = true;
                    this.dialog.message = message;
                    this.dialog.yesCallback = yesCallback;
                    this.dialog.noCallback = noCallback;
                    $(this.elment_id).modal('show');
                },
                showOk: (message, okValue, okCallBack = () => {
                }) => {
                    this.dialog.isYesNo = false;
                    this.dialog.okValue = okValue;
                    this.dialog.message = message;
                    this.dialog.okCallback = okCallBack;
                    $(this.elment_id).modal('show');
                }
            }
        });
    }
}