import {Abstract_workers_view_controller} from "packs/worker/javascripts/abstract_workers_view_controller";
import {Logger} from "packs/commons/javascripts/logger";
import {AlertDialog} from "packs/alert_dialog";
import Vue from 'vue/dist/vue.esm';
import $$ from "jquery";
import moment from "moment";
import {LoadingView} from "packs/loading_view";

import jQuery from 'jquery';
import 'slick-carousel';

class Jobs extends Abstract_workers_view_controller {
    get class_name(): string {
        return 'jobs';
    }

    get get_datepicker_options(): object {

        return {
            // yearRange: '2020:+0',
            dateFormat: 'yy年mm月dd日',
            altField: '.datepicker-target',
            altFormat: 'yy-mm-dd',
        }
    }


    initialize() {
        Logger.debug('jobs start');
    }

    init_index(self) {
        Logger.debug('jobs/index init');
        self._set_popover();

        $('[data-state-key]').on('click', (o) => {
            const key = $(o.target).data('stateKey');
            const $state = $("#q_state-sp");
            $state.val(key);
            const $form = $('#' + $state.data('applicationButton'));
            $form.submit();
        });

        $('[data-change]').on('change', (object) => {
            const $target = $(object.target)

            // 選択値をコピー
            const copyId = $target.data('target');
            $("#" + copyId).val($target.val());

            // サブミット
            const $form = $('#' + $target.data('applicationButton'));
            $form.submit()
        });

        $('[data-clear-date]').on('click', () => {
            $('.datepicker').val('');
            $('.datepicker-target').val('');
        });

        $('[data-clear]').on('click', (e) => {
            const form = $(e.target).data('clear');
            $("#" + form).find("input, select, textarea")
                .not(":button, :submit, :reset, :hidden")
                .val("")
                .prop("checked", false)
                .prop("selected", false);
            $('#begin_datetime_to_end_of_day').val('');
            $('#begin_datetime_to_end_of_day-sp').val('');

            return false;
        });

        // $('#job-list').infiniteScroll({
        //    path: '.next a',
        //    append: '.job-partial',
        //    history: false
        // });

    }

    init_show(self) {
        Logger.debug('jobs/show init');
        self._set_popover();
        jQuery('title').html(jQuery('#job-title').data().jobTitle + " | iDAバイト");
        jQuery('#slicks').slick({
            arrows: false,
            dots: true,
            infinite: false,
            rows: 1,
            slidesToShow: 3,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }]
        });

        // 利用規約・プライバシーポリシーVue
        const terms_vue = self.initTermsVue();

        $$('.job-nav a[href*="#"]').on('click', (o) => {
            const headerHeight = $('nav').get(0).offsetHeight
            let href = $(o.target).attr("href");
            href = href.substring(href.indexOf("#") + 1, href.length);

            const target = $('#' + href);
            const position = target.offset().top - headerHeight; //ヘッダの高さ分位置をずらす
            $("html, body").animate({scrollTop: position}, 300, "swing");
            return false;
        });

        $('.js-workday').on('click', (object) => {
            const target = object.target as HTMLInputElement;
            const targetId = target.id.replace(/[^0-9]/g, '');
            if (target.checked) {
                $('#form-for-job-application').append("<input value='"+targetId+"' id='workdays-"+targetId+"' type='hidden' name='workdays[]'>");
            } else {
                $("#workdays-"+targetId).remove();
            }
        });

        if ($('[data-application-button]').length > 0) {
            $('[data-application-button]').on('click', (object) => {
                if ($(".js-in-bulk").not(':checked').length > 0) {
                    AlertDialog.showOk('「全日程一括応募のみ」の求人の為、単日での応募はできません。一緒に応募するを選択してください。')
                    return
                }
                let id = $(object.target).data('applicationButton');
                const $submitBtn = $('#' + id);
                $(".js-workday:checked").each((i, elm) => {
                    $submitBtn.before($('<input>').attr({
                        type: 'hidden',
                        name: 'workdays[]',
                        value: (elm as HTMLInputElement).value
                    }));
                })
                $submitBtn[0].click();
            });
        }
    }

    init_notice(self) {

        // 利用規約・プライバシーポリシーVue
        const terms_vue = self.initTermsVue();
    }

    // 利用規約・プライバシーポリシーVue
    initTermsVue() {
        const terms_vue = new Vue({
            el: '#worker-terms',
            data: {
                terms: false
            },
            methods: {
                submit: () => {
                    terms_vue.terms = true
                }
            }
        });
        $('[data-agree]').on('click', () => {
            terms_vue.submit()
        })
        return terms_vue;
    }

    _set_popover(){
        $$('[data-toggle="popover"]').popover({
            container: 'body',
            trigger: 'manual'
        });

        $('.show-popup').on('click', function(evt) {
            evt.stopPropagation();
            $$(this).popover('show');
        });

        $('html').on('click', function(evt) {
            if (evt.target.classList[0] == 'popover-header') {

            } else if (evt.target.classList[0] == 'popover-body') {

            } else {
                $$('.show-popup').popover('hide');
            }
        });
    }
}

new Jobs();