import {Abstract_workers_view_controller} from "packs/worker/javascripts/abstract_workers_view_controller";
import {Logger} from "packs/commons/javascripts/logger";
import Vue from 'vue/dist/vue.esm';
import jQuery from 'jquery';
import 'slick-carousel';
import $$ from "jquery";
import {AlertDialog} from "packs/alert_dialog";

class Mypage extends Abstract_workers_view_controller {
    get class_name(): string {
        return 'mypage';
    }

    get get_datepicker_options(): Object {
        return {
            changeYear: true,
            changeMonth: true,
            yearRange: '-100:+0'
        }
    }

    get get_monthpicker_options(): Object {
        return {
            yearRange: '1980:+0'
        }
    }

    initialize() {
        Logger.debug('mypage start.');
    }

    init_start(self) {
        Logger.debug('mypage/start init');

        self.thumbnail_upload('worker');

        $('[data-zipcode]').on('change', (e) => {
            let zipcode = $(e.target).val();
            let groupNum = $(e.target).attr('data-zipgroup');
            self.setYubinbango(zipcode, groupNum);
        });

        // TODO：重複コード、回避策を知りたい
        const terms_vue1 = new Vue({
            el: '#terms1',
            data: {
                terms: false
            },
            methods: {
                submit: function() {
                    this.terms = !this.terms;
                },
                regist: function() {
                    if (!this.terms) return false;
                    AlertDialog.showYesNo('この内容で登録してもよろしいですか？', () => {
                        $$("#form_submit").click()
                    });
                }
            },
            filters: {}
        });
        const terms_vue2 = new Vue({
            el: '#terms2',
            data: {
                terms: false
            },
            methods: {
                submit: function() {
                    this.terms = !this.terms;
                },
                regist: function() {
                    if (!this.terms) return false;
                    AlertDialog.showYesNo('この内容で登録してもよろしいですか？', () => {
                        $$("#form_submit").click()
                    });
                }
            },
            filters: {}
        });
        $('[data-agree]').on('click', () => {
            terms_vue1.submit()
            terms_vue2.submit()
        })

    }

    init_index(self) {
        Logger.debug('mypage/index init');
    }

    init_show(self) {
        Logger.debug('mypage/show init');
        jQuery('#slicks').slick({
            infinite: false,
            arrows: false,
            dots: true
        });

        if ($('#rate_review_modal').length < 1) return;

        let rate_review_modal_vue = new Vue({
            el: '#rate_review_modal',
            data: {
                total_count: 0,
                list: [],
            },
            created: () => {
            },
            methods: {
                search: () => {
                    $$.ajax({
                        method: 'get',
                        url: "/api/get_rate_list",
                        data: {
                            q: {},
                            page: 1
                        }
                    }).done(function (msg) {
                        rate_review_modal_vue.list.splice(0, rate_review_modal_vue.list.length);
                        for (let i = 0; i < msg.length; i++) {
                            rate_review_modal_vue.list.push(msg[i]);
                            rate_review_modal_vue.total_count = msg[i].total_count;
                        }
                    }).fail((msg) => {
                    });
                }
            },
            filters: {}
        });


        $$('#rate_review_modal').on('show.bs.modal', () => {
            rate_review_modal_vue.search();
        })

    }

    init_work_history(self) {
        Logger.debug('mypage/init_work_history init');

        $('#working_from_date').on('change', function() {
            const value = $(this).val() + '-01';
            $('#worker_work_history_working_from_date').val(value);
        });
        $('#working_to_date').on('change', function() {
            const value = $(this).val() + '-01';
            $('#worker_work_history_working_to_date').val(value);
        });

        $('#is_in_employment').on('change', function() {
            if($(this).prop('checked')){
                $('#worker_work_history_working_to_date').val(null);
                $('#working_to_date').val('就業中');
                $('#working_to_date').prop('disabled', true);
            }else{
                $('#working_to_date').val(null);
                $('#working_to_date').prop('disabled', false);
            }
        });

        self.init_worktype_vue();
    }

    init_worktype_vue() {
        Vue.directive('init', {
            bind: function (el, binding, vnode) {
                vnode.context[binding.arg] = binding.value
            }
        })
        let worktype_vue = new Vue({
            data: {
                worktypes: [],
                selected: [],
                initials: {},
                periods: {}
            },
            computed: {
                deleted: function () {
                    const deleted = [];
                    const selected = this.selected.map(s => s.id);
                    Object.keys(this.initials).forEach( (key) => {
                        if (selected.includes(this.initials[key].mst_job_worktype_id)) return;
                        deleted.push(this.initials[key]);
                    });
                    return deleted;
                }
            },
            created: () => {
            },
            mounted: () => {
            },
            watch: {
                selected: function (after, before) {
                    after.forEach(v => {
                        this.$delete(v, 'deleted');
                        if (this.periods[v.id]) return;
                        this.periods[v.id] = 0.5; // 初期値
                    });
                }
            },
            methods: {
                setWorktypeName(index, name) {
                    return 'worker_work_history[worker_work_history_worktypes_attributes][' + index + '][' + name + ']';
                },
                deselected(index) {
                    this.selected.splice(index, 1);
                }
            },
            filters: {}
        });
        if($('#worktype-vue').length > 0) {
            worktype_vue.$mount('#worktype-vue');
        }
    }

    init_work_history_edit(self) {
        Logger.debug('mypage/init_work_history_updatae init');

        $('#working_from_date').on('change', function() {
            const value = $(this).val() + '-01';
            $('#worker_work_history_working_from_date').val(value);
        });
        $('#working_to_date').on('change', function() {
            const value = $(this).val() + '-01';
            $('#worker_work_history_working_to_date').val(value);
        });

        $('#is_in_employment').on('change', function() {
            if($(this).prop('checked')){
                $('#worker_work_history_working_to_date').val(null);
                $('#working_to_date').val('就業中');
                $('#working_to_date').prop('disabled', true);
            }else{
                $('#working_to_date').val(null);
                $('#working_to_date').prop('disabled', false);
            }
        });

        self.init_worktype_vue();
    }

    init_profile(self) {
        Logger.debug('mypage/profile init');

        self.thumbnail_upload('worker');

        $('[data-zipcode]').on('change', (e) => {
            let zipcode = $(e.target).val();
            let groupNum = $(e.target).attr('data-zipgroup');
            self.setYubinbango(zipcode, groupNum);
        });
    }

    init_image_asset(self) {
        self.thumbnail_upload('worker');
    }

    init_identification(self) {

        $('[data-upload]').on('click', (ele) => {
            let target = $(ele.currentTarget).attr('data-upload');
            $('input[data-target="' + target + '"]').click();
        });

        // 削除ボタン押下
        $('[data-close]').on('click', (ele) => {
            let target = $(ele.currentTarget).attr('data-close');
            $(ele.currentTarget).addClass('d-none');
            $(`#${target}>p`).removeClass('d-none');
            $(`#${target}`).css('background-image', '');
            $(`#${target}`).next().val('');
            let name = $(ele.currentTarget).attr('data-close-name');
            $(`#${target}`).append('<input type="hidden" name="' + name + '" value="1">');
        });

        // アップロードするファイルを選択
        $('input[type=file][data-target]').on('change', function (ele) {
            let file = $(this).prop('files')[0];
            // 画像以外は処理を停止
            if (!file.type.match('image.*')) {
                // クリア
                $(this).val('');
                return;
            }

            let target_id = $(this).attr('data-target');
            $(target_id).find('input[type="hidden"]').remove();
            $(target_id).find('p').addClass('d-none');
            $(target_id).find('[data-close]').removeClass('d-none');


            // 画像表示
            let reader = new FileReader();
            reader.onload = function () {
                $(target_id).css('background-image', "url(" + reader.result + ")");
                // $(target_id).css('background-size', "cover");
            }
            reader.readAsDataURL(file);
        });
    }

    // 銀行選択画面
    init_bank(self) {

        let mst_bank_vue = new Vue({
            el: '#mst_bank-select-modal',
            data: {
                message: '銀行名を入力して検索してください',
                keyword: '',
                isSearch: false,
                list: [],
                select_object: null,
            },
            methods: {
                select_bank: () => {
                    if (mst_bank_vue.select_object == null) {
                        AlertDialog.showOk('銀行を選択してください。');
                        return;
                    }

                    Logger.debug(mst_bank_vue.select_object);
                    let bank_code = $('#worker_worker_bank_account_attributes_bank_code').val();
                    $('#worker_worker_bank_account_attributes_bank_code').val(mst_bank_vue.select_object.code);
                    $('#bank_name').html(mst_bank_vue.select_object.name);

                    // 銀行が変わった場合は支店を初期化する
                    if (bank_code != mst_bank_vue.select_object.code) {
                        $('#worker_worker_bank_account_attributes_store_code').val('');
                        $('#bank_branch_name').html('');
                        mst_bank_branch_vue.init();
                    }
                    $('[data-target="#mst_bank_branch-select-modal"]').removeAttr('disabled');

                    $$('#mst_bank-select-modal').modal('hide');
                },
                search: () => {

                    let keyword = mst_bank_vue.keyword;
                    if (keyword == '') {
                        AlertDialog.showOk('銀行名を入力してください。');
                        return;
                    }

                    $$.ajax({
                        method: 'get',
                        url: "/api/get_bank",
                        data: {
                            q: {
                                name_or_kana_or_hira_or_roma_cont: keyword
                            },
                            page: 1
                        }
                    }).done(function (msg) {
                        mst_bank_vue.list.splice(0, mst_bank_vue.list.length);

                        Logger.debug(mst_bank_vue.list);

                        for (let i = 0; i < msg.length; i++) {
                            mst_bank_vue.list.push(msg[i]);
                        }

                    }).fail((msg) => {
                    });
                }
            },
            filters: {}
        });

        let mst_bank_branch_vue = new Vue({
            el: '#mst_bank_branch-select-modal',
            data: {
                message: '支店名を入力して検索してください',
                keyword: '',
                isSearch: false,
                list: [],
                select_object: null,
            },
            methods: {
                init: () => {
                    mst_bank_branch_vue.message = '支店名を入力して検索してください';
                    mst_bank_branch_vue.list.splice(0, mst_bank_branch_vue.list.length);
                },
                select_bank: () => {
                    if (mst_bank_branch_vue.select_object == null) {
                        AlertDialog.showOk('支店を選択してください。');
                        return;
                    }

                    $('#worker_worker_bank_account_attributes_store_code').val(mst_bank_branch_vue.select_object.code);
                    $('#bank_branch_name').html(mst_bank_branch_vue.select_object.name);
                    $$('#mst_bank_branch-select-modal').modal('hide');
                },
                search: () => {

                    let keyword = mst_bank_branch_vue.keyword;
                    if (keyword == '') {
                        AlertDialog.showOk('支店名を入力してください。');
                        return;
                    }

                    let bank_code = $('#worker_worker_bank_account_attributes_bank_code').val();
                    bank_code = ('0000' + bank_code).slice(-4);
                    $$.ajax({
                        method: 'get',
                        url: "/api/get_bank_branch",
                        data: {
                            q: {
                                mst_bank_code_eq: bank_code,
                                name_or_kana_or_hira_or_roma_cont: keyword
                            },
                            page: 1
                        }
                    }).done(function (msg) {
                        mst_bank_branch_vue.list.splice(0, mst_bank_branch_vue.list.length);

                        for (let i = 0; i < msg.length; i++) {
                            mst_bank_branch_vue.list.push(msg[i]);
                        }

                    }).fail((msg) => {
                    });
                }
            },
            filters: {}
        });

    }

}

new Mypage();