import $ from "jquery";

export class LoadingView {
    private static element_id: string = '#loading_view';

    static show() {
        $(this.element_id).removeClass('d-none');
    }

    static hide() {
        $(this.element_id).removeClass('d-none');
        $(this.element_id).addClass('d-none');
    }
}