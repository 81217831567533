import {Abstract_workers_view_controller} from "packs/worker/javascripts/abstract_workers_view_controller";
import {Logger} from "packs/commons/javascripts/logger";
import {AlertDialog} from "packs/alert_dialog";
import Vue from 'vue/dist/vue.esm';
import $$ from "jquery";
import moment from "moment";
import {LoadingView} from "packs/loading_view";


class Messages extends Abstract_workers_view_controller {
    get class_name(): string {
        return 'messages';
    }

    initialize() {
        Logger.debug('messages start');
    }

    init_index(self) {
        Logger.debug('messages/index init');
    }

    messagebody_resize() {
        let wh = $(window).height();
        let wx = $(window).width();
        let is_pc = wx >= 768;
        let header_height = $('.navbar').outerHeight();
        let breadcrumbs_height = $('.breadcrumbs').outerHeight();
        let footer_height = $('.message-send').outerHeight();
        let body_height = wh - header_height - breadcrumbs_height - footer_height;

        if (is_pc) {
            $('.main-message-body').css('height', body_height + 'px');
        } else {
            $('.messageitem-list').css('height', (body_height - 15) + 'px');
        }

    }

    auto_height() {
        let wx = $(window).width();
        let is_pc = wx >= 768;
        $('[data-auto-height]').each((index, el) => {
            if ($(el).data('autoHeight') == 'pc' && !is_pc) return;

            let all_height = 0;
            $(el).siblings().each((n, sib_e) => {
                all_height += $(sib_e).outerHeight(true);
            });
            let max_height = $(el).parent().height() - all_height;

            $(el).css('height', max_height + 'px');
        });
    }

    init_show(self) {
        Logger.debug('messages/show init');

        self.messagebody_resize();
        self.auto_height();

        let timerId = null;
        window.addEventListener("resize", () => {
            if (timerId) clearTimeout(timerId);

            timerId = setTimeout(() => {
                self.messagebody_resize();
                self.auto_height();
            }, 200);
        });

        // 選択後のvueオブジェクト
        let message_item_list_vue = new Vue({
            el: '#message_item_list_vue',
            data: {
                message_id: 0,
                list: [],
                result_message: '',
                page: 1,
            },
            mounted() {
                this.message_id = $('#message_item_list_vue').attr('data-message-id');
                LoadingView.show();
                this.getlist().then(() => {
                    LoadingView.hide();
                    this.$el.scrollIntoView(false);
                });


                $('#messageitem-list').on('scroll', (i) => {
                    let a = i.currentTarget;
                    if (a.scrollTop == 0) {
                        message_item_list_vue.page++;
                        message_item_list_vue.getlist(message_item_list_vue.page);
                    }
                });
            },
            computed: {
                file_icon () {
                    return function(type) {
                        if (type.startsWith('image/')) return 'fa-file-image';
                        return 'fa-file';
                    }
                },
            },
            methods: {
                getlist: async function (page_num = 1) {
                    const self = this;
                    await $$.ajax({
                        method: 'get',
                        url: "/messages/items/" + this.message_id + ".json",
                        data: {
                            page: page_num
                        }
                    }).done(function (msg) {
                        if (!msg || msg != null && msg.length == 0) {
                            self.result_message = '見つかりませんでした。キーワードを変更して検索してください。';
                            return false;
                        }
                        let messages = [];
                        let before = 0;
                        msg.forEach((d, i) => {
                            // リンク文字列変換
                            let exp = /(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
                            d['message_text'] = d['message_text'].replace(exp, "<a href='$1' class='text-link'>$1</a>");
                            messages.push(d);
                            if (i - 1 < 0) return;

                            if (d.date != messages[i - 1].date) {
                                messages[i - 1]['is_change_date'] = true;
                            }
                        });
                        if (page_num == 1) {
                            self.list.splice(0, self.list.length);
                            self.list.push(...messages.reverse());
                        } else {
                            let beforeheight = $('#messageitem-list')[0].scrollHeight;
                            self.list.unshift(...messages.reverse());

                            setTimeout(() => {
                                let afterheight = $('#messageitem-list')[0].scrollHeight;
                                let diffheight = afterheight - beforeheight;
                                $('#messageitem-list').scrollTop(diffheight);
                            }, 10);
                        }

                    }).fail((msg) => {
                    });
                }
            }
        });

        // 選択後のvueオブジェクト
        let message_send_vue = new Vue({
            el: '#message_send_vue',
            data: {
                message_text: '',
                alert_message: '',
                files: [],
                thumbnails: [],
                isDragOver: false,
                windowWidth: 0
            },
            computed: {
                has_message: function () {
                    return this.message_text != '' || this.files.length > 0;
                },
                message_row: function () {
                    return window.innerWidth <= 768 ? '1' : '2';
                }
            },
            methods: {
                onDrag(type) {
                    this.isDragOver = type === "over";
                },
                onDrop(e) {
                    this.isDragOver = false;
                    this.append_files(e.dataTransfer.files);
                },
                removeFile: function (i) {
                    this.alert_message = ''
                    this.files.splice(i, 1);
                    this.thumbnails.splice(i, 1);
                },
                fileSelect: function () {
                    this.alert_message = ''
                    this.$refs.files.click();
                },
                selectedFile: function(e) {
                    e.preventDefault();
                    this.append_files(e.target.files)
                },
                append_files: function (files) {
                    let i = this.files.length
                    for (let file of files) {
                        if (i >= 5) {
                            this.alert_message = "同時にアップロード可能なファイルは5つまでです。"
                            return
                        }
                        this.files.push(file)
                        const key = i;
                        i++;

                        if (!file.type.match('image.*')) {
                            this.$set(this.thumbnails, key, null)
                            continue;
                        }
                        let reader = new FileReader();
                        reader.onload = (e) => {
                            this.$set(this.thumbnails, key, reader.result)
                        };
                        reader.readAsDataURL(file);
                    }
                },
                send_message: async function () {
                    if (this.files.length < 1 && !this.message_text) {
                        LoadingView.hide();
                        return
                    }
                    let fd = new FormData();
                    fd.append('message_item[message_id]', message_item_list_vue.message_id);
                    fd.append('message_item[kind]', '0');
                    fd.append('message_item[status]', '0');
                    fd.append('message_item[message_text]', this.message_text);
                    for (let file of this.files) {
                        fd.append('message_item[files][]', file)
                    }

                    const self = this;
                    LoadingView.show();
                    await $$.ajax({
                        method: 'post',
                        url: "/messages.json",
                        contentType: false,
                        processData: false,
                        data: fd,
                        type: 'json'
                    }).then((...args) => {
                        // Logger.debug(msg);
                        const [msg, textStatus, jqXHR] = args;
                        self.$refs.files.value = '';
                        self.files = [];
                        self.message_text = "";
                        message_item_list_vue.list.push(msg);
                    }).catch((xhr:XMLHttpRequest, textStatus, errorThrown) => {
                        try {
                            const res = $$.parseJSON(xhr.responseText);
                            AlertDialog.showOk(res.full_message)
                        } catch (e) {
                            AlertDialog.showOk("送信できませんでした。")
                        }
                    }).then(() => {
                        LoadingView.hide();
                        message_item_list_vue.$el.scrollIntoView(false);
                    });
                }
            }
        });

        $("#send-message").on('click', function () {
            LoadingView.show();
            message_send_vue.send_message();
        });


    }
}

new Messages();