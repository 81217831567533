import {Abstract_workers_view_controller} from "packs/worker/javascripts/abstract_workers_view_controller";
import {Logger} from "packs/commons/javascripts/logger";
import {AlertDialog} from "packs/alert_dialog";
import Vue from 'vue/dist/vue.esm';
import $$ from "jquery";
import moment from "moment";
import {LoadingView} from "packs/loading_view";


class Schedule extends Abstract_workers_view_controller {
    get class_name(): string {
        return 'schedule';
    }

    get get_monthpicker_options(): Object {
        return {
            // yearRange: '2020:+0',
            dateFormat: 'yy年mm月',
            altField: '#jquery-ui-datepicker-alternate',
            altFormat: 'yy-mm',
        }
    }

    initialize() {
        Logger.debug('schedule start');
    }

    event_search_month() {
        $('[data-search-month]').on('change', (ele) => {
            $('#search_month_submit')[0].click();
        });
    }

    init_salary(self) {
        Logger.debug('schedule/salary init');
        self.event_search_month(); // 月検索機能
    }

    // 勤怠一覧
    init_index(self) {
        Logger.debug('schedule/index init');
        self.event_search_month(); // 月検索機能

        let work_show_vue = new Vue({
            el: '#work_show_vue',
            data: {
                employment: {
                    isEdit: false,
                    worker_employment_id: '',
                    name: '',
                    startDate: '',
                    startTime: '',
                    endDate: '',
                    endTime: '',
                    restTime: '',
                    workTime: '',
                    baseDate: '',
                    job: {
                        title: '',
                        beginDatetime: '',
                        endDatetime: '',
                        restTime: ''
                    }
                }
            },
            computed: {
                beginDatetime: function () {
                    if (this.employment.job.beginDatetime == '') return false;
                    const begin = moment(this.employment.job.beginDatetime);
                    return begin;
                },
                endDatetime: function () {
                    if (this.employment.job.endDatetime == '') return false;
                    const end = moment(this.employment.job.endDatetime);
                    return end;
                },
                actualHour: function () {
                    if (!this.beginDatetime) return 0;
                    if (!this.endDatetime) return 0;
                    const minutes = this.endDatetime.diff(this.beginDatetime, 'minutes') - parseInt(this.employment.job.restTime);
                    return Math.floor((minutes / 60) * 10) / 10;
                }
            },
            created: () => {
                moment.locale('ja');
            },
            methods: {
                setTime: (startAt, endAt, restTime) => {

                    work_show_vue.employment.startDate = startAt.split(' ')[0];
                    work_show_vue.employment.startTime = startAt.split(' ')[1];
                    work_show_vue.employment.endDate = endAt ? endAt.split(' ')[0] : work_show_vue.employment.baseDate;
                    work_show_vue.employment.endTime = endAt.split(' ')[1];
                    work_show_vue.employment.restTime = restTime;


                    // 就業時間を分に変換し差分から勤務時間を求める
                    if (startAt && endAt) {
                        let startDate = new Date(startAt).getTime() / 60000;
                        let endDate = new Date(endAt).getTime() / 60000;
                        let worktime = endDate - startDate - parseInt(restTime);
                        let worktimeHour = Math.floor(worktime / 60);
                        let worktimeMinute = ('00' + Math.floor(worktime % 60)).slice(-2); // 2桁表記
                        work_show_vue.employment.workTime = `${worktimeHour}:${worktimeMinute}`;
                    } else {
                        work_show_vue.employment.workTime = 0;
                    }
                },
                // 承認
                doApprove: () => {
                    AlertDialog.showYesNo('本当に承認して良いですか？', () => {

                        LoadingView.show(); // now loading

                        $.ajax({
                            method: 'post',
                            url: "/schedule/approve_worker_employment",
                            data: {
                                worker_employment_id: work_show_vue.employment.worker_employment_id,
                            },
                            type: 'json'
                        }).done((json) => {
                            LoadingView.hide();

                            setTimeout(() => {
                                if (!json) {
                                    AlertDialog.showOk('システムエラーが発生しました。');
                                    return false;
                                }

                                Logger.debug(json.messages);
                                if (json.messages) {
                                    AlertDialog.showOk(json.messages);
                                    return false;
                                }

                                $$('#work-show-modal').modal('hide');
                                AlertDialog.showOk('承認が完了しました。', '閉じる', () => {
                                    location.reload();
                                });

                            }, 300);

                        }).fail(() => {
                            LoadingView.hide();
                            AlertDialog.showOk('通信エラーが発生しました。再度操作をしてください。');
                        });

                    });
                },

                // 申請
                doRequest: () => {
                    AlertDialog.showYesNo('本当に申請してよろしいですか？', () => {
                        LoadingView.show(); // now loading

                        let employment = work_show_vue.employment;
                        $.ajax({
                            method: 'post',
                            url: "/schedule/request_worker_employment",
                            data: {
                                worker_employment_id: `${employment.worker_employment_id}`,
                                start_at: `${employment.startDate} ${employment.startTime}`,
                                end_at: `${employment.endDate} ${employment.endTime}`,
                                rest_time: employment.restTime
                            },
                            type: 'json'
                        }).done((json) => {
                            LoadingView.hide();

                            setTimeout(() => {
                                if (!json) {
                                    AlertDialog.showOk('システムエラーが発生しました。');
                                    return false;
                                }

                                Logger.debug(json.messages);
                                if (json.messages) {
                                    AlertDialog.showOk(json.messages);
                                    return false;
                                }

                                $$('#work-show-modal').modal('hide');
                                setTimeout(() => {
                                    AlertDialog.showOk('申請が完了しました。<br>承認をお待ちください。', '閉じる', () => {
                                        location.reload();
                                    });
                                }, 500);

                            }, 300);

                        }).fail(() => {
                            LoadingView.hide();
                        });
                    });
                }
            }
        });

        // 勤怠編集モーダル画面Vue
        let work_edit_vue = new Vue({
            el: '#work_edit_vue',
            data: {
                employment: {
                    job: {
                        title: '',
                        beginDatetime: '',
                        endDatetime: '',
                        restTime: ''
                    }
                },
                model: {
                    startTime: '',
                    endTime: '',
                    startDate: '',
                    endDate: '',
                    startHour: '',
                    startMinute: '',
                    endHour: '',
                    endMinute: '',
                    restTime: '',
                }
            },
            created: () => {
                moment.locale('ja');
            },
            computed: {
                beginDatetime: function () {
                    if (this.employment.job.beginDatetime == '') return false;
                    const begin = moment(this.employment.job.beginDatetime);
                    return begin;
                },
                endDatetime: function () {
                    if (this.employment.job.endDatetime == '') return false;
                    const end = moment(this.employment.job.endDatetime);
                    return end;
                },
                actualHour: function () {
                    if (!this.beginDatetime) return 0;
                    if (!this.endDatetime) return 0;
                    const minutes = this.endDatetime.diff(this.beginDatetime, 'minutes') - parseInt(this.employment.job.restTime);
                    return Math.floor((minutes / 60) * 10) / 10;
                }
            },
            methods: {
                dayOfWeek: (date) => {
                    const WEEK = ['日', '月', '火', '水', '木', '金', '土']
                    return WEEK[date.week()]
                },
                setInitData: function (d) {
                    this.employment = d;

                    let stime = d.startTime.split(':');
                    this.model.startTime = parseInt(stime[0]) + ':' + parseInt(stime[1]);
                    this.model.startHour = parseInt(stime[0]);
                    this.model.startMinute = parseInt(stime[1]);

                    let etime = d.endTime ? d.endTime.split(':') : [0, 0];
                    this.model.endHour = parseInt(etime[0]);
                    this.model.endMinute = parseInt(etime[1]);

                    this.model.restTime = d.restTime;
                    this.model.startDate = d.startDate;
                    this.model.endDate = d.endDate;
                },
                setCurrentTime: function (isStart) {
                    const currentTime = moment()
                    const target = isStart ? "start" : "end";
                    this.model[target + "Date"] = currentTime.format('YYYY/MM/DD');
                    this.model[target + "Hour"] = currentTime.hour();
                    this.model[target + "Minute"] = currentTime.minute();
                },
                isPrev: function (isStart) {
                    try {
                        const targetDate = isStart ? this.model.startDate : this.model.endDate;
                        const prevDate = moment(new Date(this.employment.baseDate)).add(-1, 'days'); // 基準日の前日
                        return this.diffDays(new Date(targetDate), prevDate) != 0;  // 前日ではない場合はtrue
                    } catch (e) {
                    }
                },
                isNext: function (isStart) {
                    try {
                        const targetDate = isStart ? this.model.startDate : this.model.endDate;
                        const nextDate = moment(new Date(this.employment.baseDate)).add(1, 'days'); // 基準日の次の日
                        return this.diffDays(moment(new Date(targetDate)), nextDate) != 0;  // 次の日ではない場合はtrue
                    } catch (e) {
                    }
                },
                diffDays: function (baseDate, diffDate) {
                    return diffDate.diff(baseDate);
                },
                prevDay: function (isStart) {
                    try {
                        const targetDate = isStart ? this.model.startDate : this.model.endDate;
                        const date = moment(new Date(targetDate)).add(-1, 'days').format('YYYY/MM/DD');
                        if (isStart) this.model.startDate = date;
                        else this.model.endDate = date;
                    } catch (e) {
                        console.log(e);
                    }
                },
                nextDay: function (isStart) {
                    try {
                        const targetDate = isStart ? this.model.startDate : this.model.endDate;
                        const date = moment(new Date(targetDate)).add(1, 'days').format('YYYY/MM/DD');
                        if (isStart) this.model.startDate = date;
                        else this.model.endDate = date;
                    } catch (e) {
                        console.log(e);
                    }
                },

                // 編集確定時
                doConfirm: () => {

                    // validation
                    let errMessage = work_edit_vue.validation();
                    if (errMessage != '') {
                        AlertDialog.showOk(errMessage);
                        return;
                    }

                    // 大元のデータを更新する
                    let model = work_edit_vue.model;
                    work_show_vue.employment.isEdit = true;
                    let sDate = moment(new Date(`${model.startDate} ${model.startHour}:${model.startMinute}`));
                    let eDate = moment(new Date(`${model.endDate} ${model.endHour}:${model.endMinute}`));
                    work_show_vue.setTime(sDate.format('YYYY/MM/DD HH:mm'), eDate.format('YYYY/MM/DD HH:mm'), model.restTime);

                    // モーダル閉じる
                    $$('#work-edit-modal').modal('hide');
                },

                // 入力チェック, @return '' => ok
                validation: () => {
                    let resultMessage = '';
                    let model = work_edit_vue.model;

                    // 必須チェック
                    if ((model.startHour !== 0 && !model.startHour) || (model.startMinute !== 0 && !model.startMinute)) resultMessage += '出勤日時を設定してください。<br>';
                    if ((model.endHour !== 0 && !model.endHour) || (model.endMinute !== 0 && !model.endMinute)) resultMessage += '退勤日時を設定してください。<br>';
                    if (model.restTime !== 0 && !model.restTime) resultMessage += '休憩時間を設定してください。<br>';
                    if (resultMessage != '') return resultMessage;

                    if (model.startMinute >= 60) resultMessage += '出勤日時が不正です<br>';
                    if (model.endMinute >= 60) resultMessage += '退勤日時が不正です<br>';

                    let sDate = moment(new Date(`${model.startDate} ${model.startHour}:${model.startMinute}`));
                    let eDate = moment(new Date(`${model.endDate} ${model.endHour}:${model.endMinute}`));

                    // 勤務時間より休憩時間が長い場合
                    if (parseInt(model.restTime) > 0 && eDate.diff(sDate, 'minutes') < parseInt(model.restTime)) resultMessage += '勤務時間より長い休憩は設定できません。<br>';

                    // 日付逆転チェック
                    if (sDate.diff(eDate) > 0) resultMessage += '出勤日時が退勤日時より前には設定できません。<br>';

                    // 時間差をみる
                    let minutes = Math.floor(eDate.diff(sDate, 'minutes'));
                    let work_time = (minutes / 60);
                    if (work_time > 8 && parseInt(model.restTime) < 60) resultMessage += '8時間を超えて勤務する場合、休憩時間を60分未満に設定できません。<br>';
                    if (work_time > 6 && parseInt(model.restTime) < 45) resultMessage += '6時間を超えて勤務する場合、休憩時間を45分未満に設定できません。<br>';

                    return resultMessage;
                }
            }
        });

        const rateVue = new Vue({
            data: {
                job: {
                    title: '',
                    beginDatetime: '',
                    endDatetime: ''
                },
                rate: null,
                memo: '',
                isRated: false,
                employmentId: null
            },
            computed: {
                beginDatetime: function () {
                    if (this.job.beginDatetime == '') return false;
                    const begin = moment(this.job.beginDatetime);
                    return begin;
                }
            },
            created: () => {
                moment.locale('ja');
            },
            methods: {
                setInitData: function (id, job, isRated, isGood = null, memo = '') {
                    this.isRated = isRated;
                    this.employmentId = id;


                    this.rate = null;
                    this.memo = '';
                    if (isRated) {
                        this.rate = (isGood) ? 1 : 0;
                        this.memo = memo;
                    }

                    this.job.title = job.title;
                    this.job.beginDatetime = job.beginDatetime;
                    this.job.endDatetime = job.endDatetime;

                },
                doConfirm: function () {
                    AlertDialog.showYesNo('評価を確定してよろしいですか？', () => {
                        $('#submit-rate').click();
                    });
                }
            }
        });
        rateVue.$mount('#rate-vue');

        // ワーカー評価を作成します。
        $('[data-rate-modal]').on('click', (event) => {
            const obj = $(event.target);
            const group_id = obj.attr('data-group-id');

            const employmentId = obj.data('employmentId');
            const job = obj.data('job');
            const isRated = obj.data('isRated');
            const isGood = obj.data('isGood');
            const memo = obj.data('memo') || '';
            rateVue.setInitData(employmentId, job, isRated, isGood, memo);

            $$(`#rate-edit-modal-${group_id}`).modal('show');
            return false;
        });


        // 勤務情報を表示します
        $('[data-work-show]').on('click', (event) => {

            AlertDialog.showYesNo('勤務終了後に<br>出勤・退勤を一度のみ打刻してください', () => {
                let obj = $(event.target);

                let startAt = obj.attr('data-start-time');
                let endAt = obj.attr('data-end-time');
                let restTime = obj.attr('data-rest-time');
                let approve_status = obj.attr('data-approve-status');

                // 基本データ設定
                work_show_vue.employment.isEdit = false;
                work_show_vue.employment.worker_employment_id = obj.attr('data-worker-employment-id');
                work_show_vue.employment.approve_status = approve_status;
                work_show_vue.employment.name = obj.attr('data-name');
                work_show_vue.employment.baseDate = obj.attr('data-base-date');
                work_show_vue.setTime(startAt, endAt, restTime);
                work_show_vue.employment.job = obj.data('job');

                // 編集画面にもデータをコピーする
                work_edit_vue.setInitData(work_show_vue.employment);

                // 勤怠詳細画面を表示する
                $$(`#work-show-modal`).modal('show');
                if (parseInt(approve_status) === 0) {
                    $$(`#work-edit-modal`).modal('show');
                }
            });

            return false;
        });
    }

}

new Schedule();