import {Abstract_workers_view_controller} from "packs/worker/javascripts/abstract_workers_view_controller";
import {Logger} from "packs/commons/javascripts/logger";
import {AlertDialog} from "packs/alert_dialog";
import Vue from 'vue/dist/vue.esm';
import $$ from "jquery";
import moment from "moment";
import {LoadingView} from "packs/loading_view";


class Clients extends Abstract_workers_view_controller {

    get class_name(): string {
        return 'clients'
    }

    initialize() {
        Logger.debug('clients start');
    }

    init_index(self) {
        Logger.debug('clients/index init');
    }

    init_show(self) {
        Logger.debug('clients/show init');


        // 選択後のvueオブジェクト
        let recruiting_jobs_vue = new Vue({
            el: '#recruiting_jobs_vue',
            data: {
                client_id: 0,
                job_workdays: [],
            },
            methods: {
                init: () => {
                    recruiting_jobs_vue.client_id = $('#recruiting_jobs_vue').attr('data-client-id');
                    recruiting_jobs_vue.getlist();
                },
                getlist: () => {

                    $$.ajax({
                        method: 'get',
                        url: "/client/recruiting_jobs/" + recruiting_jobs_vue.client_id + '.json',
                        data: {
                            page: 1
                        }
                    }).done(function (msg) {
                        Logger.debug(msg);

                        if (!msg || msg != null && msg.length == 0) {
                            return false;
                        }
                        msg.forEach((d, index) => {
                            recruiting_jobs_vue.job_workdays.push(d);
                        });
                    }).fail((msg) => {
                        Logger.debug("error. client/recruiting_jobs/" + recruiting_jobs_vue.client_id + ".json");
                    });
                }
            }
        });
        recruiting_jobs_vue.init();
    }
}

new Clients();