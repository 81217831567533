import {Abstract_workers_view_controller} from "packs/worker/javascripts/abstract_workers_view_controller";
import {Logger} from "packs/commons/javascripts/logger";
import {AlertDialog} from "packs/alert_dialog";
import Vue from 'vue/dist/vue.esm';
import $$ from "jquery";
import moment from "moment";
import {LoadingView} from "packs/loading_view";


class Favorites extends Abstract_workers_view_controller {
    get class_name(): string {
        return 'favorites';
    }

    initialize() {
        Logger.debug('favorites start');
    }

    init_index(self) {
        Logger.debug('favorites/index init');
    }

    init_show(self) {
        Logger.debug('favorites/show init');
    }
}

new Favorites();