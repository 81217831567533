// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
import 'bootstrap';
import '../commons/javascripts/logger'
import '../commons/javascripts/initialize'
import "@fortawesome/fontawesome-free/js/all"
import './javascripts/jobs'
import './javascripts/messages'
import './javascripts/favorites'
import './javascripts/clients'
import './javascripts/mypage'
import './javascripts/schedule'
import './javascripts/faqs'

import './javascripts/serviceworker-companion'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import './stylesheets/application.scss'
import '../commons/stylesheets/jquery-ui.min.css'
import '../commons/stylesheets/jquery-ui.structure.min.css'
import '../commons/stylesheets/jquery-ui.theme.min.css'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
