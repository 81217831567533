import {Abstract_view_controller} from "packs/commons/javascripts/abstract_view_controller";
import {AlertDialog} from "packs/alert_dialog";
import $$ from "jquery";
import {Logger} from "packs/commons/javascripts/logger";
import {YubinbangoCore} from "vendor/yubinbango/yubinbango-core";

export abstract class Abstract_workers_view_controller extends Abstract_view_controller {
    get role_name(): string {
        return "worker";
    }

    get get_datepicker_options(): object {
        return {};
    }
    get get_monthpicker_options(): object {
        return {};
    }

    setYubinbango(zipcode, groupNum) {
        new YubinbangoCore(zipcode.toString(), function (addr) {
            Logger.debug(addr)  // 市区町村
            $(`.p-region-${groupNum}`).val('' + addr.region_id);
            $(`.p-locality-${groupNum}`).val(addr.locality);
            $(`.p-street-${groupNum}`).val(addr.street);
        })
    }

    // 削除ボタン押下時
    event_delete() {
        $('[data-delete-id]').on('click', (event) => {

            // 削除OKフラグが存在しない場合はダイアログを表示
            if ($(event.target).attr('data-visit')) return true;

            AlertDialog.showYesNo('本当に削除して良いですか？', () => {
                $(event.target).attr('data-visit', '1');
                $(event.target)[0].click();
            });
            return false;
        });
    }

    // ステータス変更押下時
    event_status_change() {
        $('[data-status-change]').on('click', (event) => {
            $('#status_select').val($(event.target).attr('data-default-id'));
            $('#status_change_id').val($(event.target).attr('data-status-change'));
            $$('#status-change-modal').modal('show');
            return false;
        });
    }


    // 汎用サブミットダイアログ
    standard_submit_dialog() {
        if ($('[data-submit-button]').length > 0) {
            $('[data-submit-button]').on('click', (object) => {
                let message = $(object.target).attr('data-confirm-message');
                let id = $(object.target).attr('data-submit-button');
                let submit_object = $('#' + id);
                AlertDialog.showYesNo(message, () => {
                    submit_object[0].click();
                });
            });
        }
    }

    standard_alert_dialog() {
        if ($('[data-click-button]').length > 0) {
            $('[data-click-button]').on('click', (object) => {
                let message = $(object.target).attr('data-message');
                let id = $(object.target).attr('data-click-button');
                let submit_object = $('#' + id);
                if (!message) {
                    submit_object[0].click();
                    return;
                }
                AlertDialog.showYesNo(message, () => {
                    submit_object[0].click();
                });
            });
        }
    }

    copy_url() {
        $(document).on("click", '[data-copy-button]', (object) => {
            $('body').append('<textarea id="currentURL" style="position:fixed;left:-100%;">' + location.href + '</textarea>');
            $('#currentURL').select();
            document.execCommand('copy');
            $('#currentURL').remove();
            AlertDialog.showOk("URLをコピーしました。")
        });
    }

    before() {
        Logger.debug('before called.');

        const thisDup = this;
        window.addEventListener('appinstalled', function() {
            thisDup.updateWorkerInstalledPwaAt();
            document.cookie = "request_from_pwa=true"
        });

        // PWAからのアクセス判定
        if (window.matchMedia('(display-mode: standalone)').matches) {
            console.log("this request IS from PWA");
            document.cookie = "request_from_pwa=true"
            let floatedBottomContainer = document.getElementsByClassName('floated-bottom-container');
            if (floatedBottomContainer.length > 0) { floatedBottomContainer[0].classList.add('pb-4'); }
            let pwaRecommendArea = document.getElementsByClassName('pwa-recommend-area');
            if (pwaRecommendArea.length > 0) { pwaRecommendArea[0].remove(); }
        } else {
            console.log("this request is NOT from PWA");
            document.cookie = "request_from_pwa=false"
        }

        // 汎用サブミットダイアログ
        this.standard_submit_dialog();

        // 汎用クリックアラートダイアログ
        this.standard_alert_dialog();

        // Urlコピー
        this.copy_url();

        // クリアボタン
        $('[data-clear]').on('click', (e) => {
            const target = $(e.target).data('clear')
            $('#' + target).removeAttr('value')
        })

        // タイトルの置き換え
        if ($('#data-nav-title').length > 0) {
            let title_html = $('#data-nav-title').html();
            $('#data-nav-title').remove();
            $('[data-append-header]').html(title_html);
        }

        // 戻るリンク置き換え
        if ($('#data-nav-back').length > 0) {
            let back_html = $('#data-nav-back').html();
            $('#data-nav-back').remove();
            $('[data-nav-back]').html(back_html);
        }

        // アラート準備
        AlertDialog.loadAlertVue();

        // フラッシュメッセージが存在した場合
        $('[data-flash-message]').each((index, ele) => {
            let okValue = ele.getAttribute('data-flash-ok');
            AlertDialog.showOk(ele.innerHTML, okValue);
        });

        // 汎用全チェック機能
        $('[data-all-check]').on('change', (obj) => {
            let radios = $(obj.target).attr('data-all-check');
            $(`[${radios}]`).prop('checked', $(obj.target).prop('checked'));
        });

        // ピッカー系
        this.set_datepicker();
        this.set_monthpicker();

    }

    updateWorkerInstalledPwaAt() {
        console.log('PWA was installed');
        $.ajax({
            method: 'post',
            url: "update_installed_pwa_at",
        }).done((json) => {
            console.log('json', json);
        }).fail(() => {
        });
    }
}
