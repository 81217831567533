import {ViewControllerInterface} from "packs/commons/javascripts/view_controller_interface";
import {Logger} from "packs/commons/javascripts/logger";
import jQuery from "jquery";
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-ja'
import '../../../vendor/month_picker/month_picker.js'

export abstract class Abstract_view_controller implements ViewControllerInterface {
    abstract before();

    abstract initialize();

    abstract get role_name(): string;

    abstract get class_name(): string;

    // アンカーまでスクロールする
    scroll_hash() {
        let hash = $(location).attr('hash');
        if (hash) {
            let target = $(hash).offset().top;
            $('html,body').animate({scrollTop: target}, 'fast');
        }
    }

    get get_datepicker_defaults(): object {
        return {
            showMonthAfterYear: true,
            showButtonPanel: true,
            showOtherMonths: true,
            beforeShow: function (input, inst) {
                jQuery('#datepicker-wrapper').show();
                return null;
            },
            onClose: function (input, inst) {
                jQuery('#datepicker-wrapper').hide();
                return;
            }
        };
    }

    abstract get get_datepicker_options(): object;

    get get_monthpicker_defaults(): object {
        return {
            changeYear: true,
            dateFormat: 'yy/mm',
            showButtonPanel: true,
            closeText: "閉じる",
            monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            beforeShow: function (input, inst) {
                jQuery('#datepicker-wrapper').show();
                return null;
            },
            onClose: function (input, inst) {
                jQuery('#datepicker-wrapper').hide();
                return;
            }
        }
    }

    abstract get get_monthpicker_options(): object;


    set_datepicker() {
        const options = {...this.get_datepicker_defaults, ...this.get_datepicker_options}
        $('[datepicker]').each((index, ele) => {
            jQuery(ele).datepicker(options);
        });
    }

    set_monthpicker() {
        const options = {...this.get_monthpicker_defaults, ...this.get_monthpicker_options}
        jQuery('[monthpicker]').monthpicker(options);
    }

    constructor() {
        document.addEventListener("turbolinks:load", () => {
            let class_name = this.class_name;
            let parent_name = this.role_name;
            if ($('body[data-controller="' + parent_name + '/' + class_name.toLowerCase() + '"]').length > 0) {
                Logger.debug(parent_name + '/' + class_name.toLowerCase() + '.initialize: call')
                this.before();
                this.initialize();
                let controller_object = this;
                let action_name = $('body').data('method');
                let function_pointer = eval('controller_object.init_' + action_name)
                if (function_pointer != undefined) {
                    Logger.debug(parent_name + '/' + class_name.toLowerCase() + '.init_' + action_name + ': call')
                    function_pointer(this);
                } else {
                    Logger.debug(parent_name + '/' + class_name.toLowerCase() + '.init_' + action_name + ': method not found')
                }
                // 非同期読み込みを兼ねて、10msほどずらしてからアンカーまでスクロール
                setTimeout(() => {
                    this.scroll_hash();
                }, 10);
            }
        });
    }

    // サムネイルアップロード共通処理
    thumbnail_upload(target_name) {

        // イメージ追加ボタン押下
        $('[data-image-upload]').on('click', (e) => {
            let imageobj = $(e.currentTarget);
            imageobj.find('[data-image-file]')[0].click();
        });

        // イメージ削除ボタン押下
        $('[data-image-destroy-button]').on('click', function (e) {
            let is_new = $(e.currentTarget).attr('data-is-new') == '1';

            // アップロードする数を減らす
            let image_limit = parseInt($('#image_file_list').attr('data-limit'));
            let image_num = parseInt($('#image_file_list').attr('data-image-num'));
            $('#image_file_list').attr('data-image-num', image_num - 1);

            // 追加ボタンを最後に追加する
            if (image_num >= image_limit) {
                let cloneobj = $('#copy_image').clone(true).appendTo('#image_file_list');
                cloneobj.removeClass('d-none');
                cloneobj.removeAttr('id');
            }

            // 新規追加イメージはそのまま削除
            if (is_new) {
                $(e.currentTarget).closest('[data-image-file-parent]').remove();

            } else {

                let parent = $(e.currentTarget).closest('[data-image-file-parent]');

                // DBに追加済みのイメージは削除フラグを立てて非表示とする
                parent.find('[data-image-destroy]').val('1');
                parent.fadeOut('fast');
            }
        });

        // アップロードするファイルを選択
        $('input[type=file][data-image-file]').on('change', function (e) {
            let thisobj = $(e.currentTarget);
            let parentobj = thisobj.closest('[data-image-file-parent]');

            let file = $(this).prop('files')[0];
            // 画像以外は処理を停止
            if (!file || !file.type.match('image.*')) {
                // クリア
                $(this).val('');
                return;
            }

            // 置換判定
            const is_uploaded = parentobj.data('hasImage');

            // アップロードする画像をバックグラウンドでサムネイル表示
            let reader = new FileReader();
            reader.onload = function () {
                parentobj.data('hasImage', true);
                parentobj.css('background-image', "url(" + reader.result + ")");
                parentobj.css('background-size', "cover");
                parentobj.off(); // ボタンイベント削除
            }
            reader.readAsDataURL(file);

            // uploadされるイメージの数（削除分は含まない）
            let image_num = parseInt($('#image_file_list').attr('data-image-num')) + 1;
            let image_limit = parseInt($('#image_file_list').attr('data-limit'));

            // 削除ボタン表示
            parentobj.find('p').html('');
            parentobj.find('[data-image-destroy-button]').removeClass('d-none');

            // テキスト非表示
            parentobj.find('[data-image-upload-text]').addClass('invisible');

            // name属性の決定
            let num = parseInt($('#image_file_list').attr('data-num'));
            thisobj.attr('name', `${target_name}[${target_name}_assets_attributes][${num}][path]`);

            // 追加ボタンを最後に追加する
            if (!is_uploaded && image_num < image_limit) {
                let cloneobj = $('#copy_image').clone(true).appendTo('#image_file_list');
                cloneobj.removeClass('d-none');
                cloneobj.removeAttr('id');
            }

            // index加算
            $('#image_file_list').attr('data-num', ++num);
            $('#image_file_list').attr('data-image-num', image_num);
        });
    }
}
